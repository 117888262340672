$bk10: #2c2c2c
$black-80: #2c2c2ccc
$bk05: #9e9e9e
$bk04: #bdbdbd
$black-20: #2c2c2c33
$bk03: #eeeeee
$bk02: #f5f5f5
$white: #ffffff
$bl05: #0056ff
$pastel-blue: #a7c4ff
$light-periwinkle: #cedfff
$pale-grey: #f4f7ff
$coral: #ff5b39
