@import './_colors'

html,
body,
#root
  min-height: 100vh
  color: $bk10
  margin: 0
  padding: 0
   

body
  font-family: 'Noto Sans KR', 'sans-serif'
 
input,
select,
textarea,
button
  font-family: inherit

a
  text-decoration: none
